@charset "utf-8";
@import '../../_variables.sass';

@media print {
  .chart {
    .recharts-wrapper,
    .recharts-wrapper > svg.recharts-surface,
    .recharts-wrapper > .recharts-legend-wrapper {
      width: 100% !important;
    }

    .recharts-wrapper > svg.recharts-surface {
      height: 100% !important;
    }
  }
}
