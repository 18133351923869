@charset "utf-8";
@import '../../_variables.sass';

.footer {
  background-image: repeating-radial-gradient(
    circle,
    #ffffff0d 0px,
    rgba(255, 255, 255, 0.4) 1px,
    transparent 1px,
    transparent 100%
  );
  background-size: 18px 18px;
  .container {
    margin-bottom: 0;
  }
  .columns {
    justify-content: space-between;
    align-items: flex-start;
    .column {
      flex-basis: auto;
      flex-grow: 0;
    }
  }

  a,
  .title {
    color: $white;
  }
  a:hover {
    color: $grey-light;
  }
  svg {
    fill: $white;
    margin-bottom: -3px;
    margin-right: 0.5rem;
  }
  li {
    margin-top: 0.75rem;
    margin-bottom: 0;
  }
  .column {
    background-color: $primary;
  }
  .column:last-of-type div {
    background-color: $white;
    padding: 1rem 3rem;
    text-align: center;
  }
  .section {
    padding: 1.5rem;
  }
}
