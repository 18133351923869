@import '../../_variables.sass';

.eu-cookie-compliance-notification {
  transition: $speed;
  position: fixed;
  bottom: 0;
  width: 100%;
  z-index: 400;
  left: 0;
  max-width: 480px;

  @media (min-width: 1200px) {
    left: calc((100% - 1296px) / 2);
  }
  @media (max-width: 1407px) {
    left: calc((100% - 1106px) / 2);
  }
  @media (max-width: 1215px) {
    left: calc((100% - 912px) / 2);
  }
  @media (max-width: 1024px) {
    left: 48px;
  }
  @media (max-width: 768px) {
    left: 0px;
    max-width: 100%;
  }

  .card-content {
    padding: 42px 52px 38px;
  }

  .heading {
    margin-bottom: 19px;
    font-size: 22px;
    line-height: 26px;
    font-weight: 700;
    color: $primary;
    text-transform: none;
    letter-spacing: 0;
  }

  #popup-text .message {
    background-color: $white;

    a {
      font-weight: 700;
      color: inherit;
      transition: $speed;

      &:hover {
        color: $primary !important;
      }
    }
  }

  .actions {
    display: flex;
    gap: 20px;

    @media (max-width: 450px) {
      flex-direction: column;
    }
  }

  &.hidden {
    transform: translateY(calc(100% + 8px));
  }
}
