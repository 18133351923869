@charset "utf-8";
$hr-height: 1px;
$hr-margin: 0.5rem 0;
$hr-background-color: #dbdbdb;
$body-size: 1rem;
$detail-height: 60vh;

@import '../../_variables.sass';
@import '~bulma/bulma.sass';

// Force footer to bottom
html,
body,
#root,
.App {
  height: 100%;
}
.App {
  display: flex;
  flex-direction: column;
}
.login,
.Dashboard,
.home {
  flex: 1 0 auto;
}
.footer {
  flex-shrink: 0;
}

// general
p {
  margin-bottom: 1rem;
}

section.is-small {
  padding: $section-padding-small;
}

// Helpers
.is-spaced {
  letter-spacing: 0.005rem;
}

.is-uppercase {
  font-variant: all-small-caps;
}

.has-shadow {
  box-shadow: $card-shadow;
}

.is-light {
  background-color: $white-bis;
}

.is-flex {
  display: flex;
}

.has-text-link, .html-link-content a {
  font-weight: 700;
  color: $primary !important;
  text-decoration: underline;
}

.has-link-break {
  word-break: break-all;
}

.columns.has-right {
  justify-content: flex-end;
}

.dotted {
  background-image: repeating-radial-gradient(
    circle,
    rgba(0, 0, 0, 0.05) 0px,
    rgba(0, 0, 0, 0.3) 1px,
    transparent 1px,
    transparent 100%
  );
  background-size: 18px 18px;
  background-position-x: 3rem;
}

.dotted .title {
  background-color: $white;
  display: inline-block;
  padding: 0.2em 0.4em;
}

// Button
.button {
  font-weight: 700;
  padding: 1.3em 2.3em;
  line-height: 0;
  height: auto;
  transition: $speed;
  &[disabled] {
    opacity: 1;
    background-color: $grey-lighter !important;
    color: $grey-dark;
  }
  &.is-primary {
    transition: $transition;

    svg {
      fill: $white;
    }
    &:hover {
      border: 2px solid $primary;
      background-color: $white;
      color: $primary;
      svg {
        fill: $primary;
      }
    }
  }
  &.is-secondary {
    border-color: $primary;
    transition: $transition;

    &:hover {
      background-color: $primary;
      color: $white;

      svg {
        fill: $white;
      }
    }
  }

  &.is-link {
    border: none;
    background-color: rgba(0, 0, 0, 0);
    color: $primary;
    padding: 0.5rem;
    svg {
      margin-right: 0.5rem;
    }
    &:hover,
    &:focus {
      background-color: rgba(0, 0, 0, 0);
      color: $primary;
      text-decoration: underline;
    }
  }
  &.is-outlined {
    border: 2px solid $primary;
    background-color: $white;
  }
  &.has-icon {
    padding: 0.4em 1.6em;
    svg {
      margin-right: 0.5rem;
    }
  }
}

button.is-small,
.button.is-small {
  border-radius: 0;
  padding: 0.25rem;
  svg {
    margin-right: 0.25rem;
  }

  &.no-text {
    svg {
      margin-right: 0;
    }
  }
}

.has-addons {
  button.is-small {
    border-left: 0px solid #0000;
  }
}

// Tabs
.tabs {
  font-weight: 700;
  overflow: visible;
  li {
    a:hover {
      border-top-color: $primary;
      border-top-width: 2px;
      border-top-style: solid;
      transition: all $speed;
    }
    &.is-active {
      position: relative;
      &::before {
        content: '';
        border: 3px solid $primary;
        background-color: $primary;
        border-radius: 50%;
        position: absolute;
        left: 50%;
        bottom: -4px;
      }

      a {
        border-top-color: $primary;
        border-top-width: 2px;
        border-top-style: solid;
        border-bottom-color: $grey-light;
      }
    }
  }
}

// Modal
.modal {
  flex-direction: column;
  font-size: 1rem;
}

.modal-card-foot {
  justify-content: flex-end;
  .buttons .button {
    margin-left: 0.5rem;
  }
}

.modal-card-head {
  p {
    margin-bottom: 0;
    font-weight: 700;
  }
  .delete {
    height: 40px;
    max-height: 40px;
    max-width: 40px;
    min-height: 40px;
    min-width: 40px;
    background-color: rgba(0, 0, 0, 0);
    &::after,
    &::before {
      background-color: $primary;
    }
  }
}

.select select {
  background-color: $white;
}

// Collapse
.collapse__header {
  cursor: pointer;
}

.collapse__body {
  height: 0;
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.3s;
}

.collapse__body-open {
  max-height: 5000px;
  transition: max-height 0.5s ease-in;
}

.collapse__content {
  padding-bottom: 1.5rem;
}

.popup-overlay {
  z-index: 1;
}

.card {
  box-shadow: $card-shadow;
}

.input,
.copy-btn {
  align-items: center;
  background-color: hsl(0, 0%, 100%);
  border-color: hsl(0, 0%, 80%);
  border-radius: 0;
  border-style: solid;
  border-width: 1px;
  cursor: default;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  min-height: 38px;
  outline: 0 !important;
  position: relative;
  transition: all 100ms;
  box-sizing: border-box;

  :hover {
    border-color: hsl(0, 0%, 70%);
  }
}

.copy-btn {
  border-left: 1px solid hsl(0, 0%, 80%) !important;
  margin: 0;
}

.export-btn {
  border: 1px solid $grey-light;
}

.new-feature-modal {
  .title {
    font-size: 1.875rem;
  }
}

.admin-search-block {
  justify-content: flex-end;
}
