@charset "utf-8";
@import '../../_variables.sass';

.react-select,
.css-s7b907 {
  border: none;
  font-size: $size-7;
  .react-select__control {
    cursor: pointer;
    border: $control-border-width/2 solid $primary;
  }
  .react-select__placeholder {
    color: $primary;
    font-variant: all-small-caps;
  }
  .react-select__value-container {
    padding: 0 8px;
  }
  .react-select__indicator-separator {
    display: none;
  }
  .react-select__dropdown-indicator {
    color: $primary;
    padding: 6px;
  }
  .react-select__menu {
    border: none;
    box-shadow: $card-shadow-hover;
    margin-top: $control-border-width;
  }
  .react-select__option {
    font-size: $size-8;
    line-height: 1.3;
  }
  .react-select__option--is-focused {
    font-weight: bold;
  }
  .react-select__indicator svg {
    fill: $primary;
  }
  .react-select__multi-value__remove:hover {
    background-color: $grey-darker;
    color: $white;
  }
  &.react-select--is-disabled {
    border-color: $grey-lighter;
    .react-select__placeholder {
      color: $grey;
    }
    svg {
      fill: $grey;
    }
  }
}

// for select with portlet in navbar secondary
.react-select__menu {
  border: none;
  box-shadow: $card-shadow-hover;
  margin-top: $control-border-width;
}
.react-select__option {
  font-size: $size-8;
  line-height: 1.3;
}
.react-select__option--is-focused {
  font-weight: bold;
}
.react-select__indicator svg {
  fill: $primary;
}
.react-select__multi-value__remove:hover {
  background-color: $grey-darker;
  color: $white;
}

.custom-option {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
