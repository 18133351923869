@charset "utf-8";
@import '../../_variables.sass';

.notification.cookie-notification {
  position: fixed;
  bottom: 0;
  width: 100%;
  z-index: 2;
  .delete {
    background-color: $primary;
  }
}

.notification.is-info {
  background-color: $link-color;
  padding: 0.5em;
  margin: 0;
  border-radius: 0;

  p {
    margin: 0;
  }
}
