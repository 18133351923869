@charset 'utf-8';
@import '../../../_variables.sass';

.navbar-burger,
.navbar-burger:hover {
  background-color: $white;
  margin-left: 0;
  margin-right: -0.5rem;
  color: $primary;
  span {
    height: 2px;
  }
}

.navbar-menu.is-active {
  padding: 0;
  margin-left: -2rem;
  margin-right: -2rem;
  padding-right: 2rem;
  padding-left: 1rem;
}

.nav-item-mobile {
  display: flex;
  height: 2rem;
  background-color: $white;
  color: $primary;
  margin: 2rem 0;
  font-weight: 700;
  cursor: pointer;

  &.highlightable:nth-last-of-type(2) {
    background-color: $grey-lighter;
    align-items: center;
  }

  .dashboard,
  .back-button {
    width: 100%;
    display: flex;
    padding-left: 1em;
    font-weight: 700;
    align-items: center;
  }

  .back-button {
    padding-left: 1em;
  }

  .dashboard {
    justify-content: space-between;
  }

  .dashboard,
  .region,
  .graph {
    padding-left: 3em;
  }

  .region,
  .graph {
    flex: auto;
  }

  .button.is-primary {
    background-color: $white;
    color: $primary;
  }
}

.nav-item-mobile-user {
  height: 3rem;
  margin-top: 0;
  margin-bottom: 0;
  margin-right: -2rem;
  padding-right: 2rem;
  border-top: 1px solid $primary;

  .button,
  .button.is-active,
  .button.is-active:hover {
    padding: 1rem;
    border: none;
    border-right: 1px solid $primary;
  }
  .react-select {
    border: none;
    flex: auto;
  }
  .react-select__control,
  .react-select__control--is-focused,
  .css-o3h76h-control:hover {
    height: 100%;
    border: none !important;
    border-color: transparent !important;
    box-shadow: none;
    flex: auto;
  }
  .react-select__single-value {
    font-weight: 700;
  }
}
