
.checkbox input[type="checkbox"] {
    display: none;
}

label.checkbox {
    display: flex;
    padding-left: 10px;

    span {
    display: inline-block;
    position: relative;
    height: 20px;
    min-width: 20px;
    width: 20px;
    border: 2px solid;
    left: -10px;
    }
    span::after {
    position: absolute;
    content: "";
    display: inline-block;
    height: 5px;
    width: 10px;
    border-left: 2px solid;
    border-bottom: 2px solid;
    transform: rotate(-45deg);
    left: 3px;
    top: 4px;
    }
}

.checkbox input[type=checkbox] + span::after,
.react-select__option:not(.react-select__option--is-selected) .checkbox input[type=checkbox] + span::after {
    content: none;
}

.checkbox input[type=checkbox]:checked + span::after,
.react-select__option.react-select__option--is-selected .checkbox input[type=checkbox] + span::after {
    content: "";
}
