@charset 'utf-8';
@import '../../_variables.sass';

.headroom {
  z-index: 2 !important;
}

.navbar-wrapper {
  position: relative;
  padding-left: $navbar-margin;
  padding-right: $navbar-margin;
  @include mobile {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }
  &.navbar-primary {
    background-color: $white;
  }
  &.navbar-secondary {
    background-color: $primary;
  }
}

.header__personal__person {
  display: inline-block;
  width: auto;
  min-width: 130px;
  text-align: right;
}
