@charset 'utf-8';
@import '../../../_variables.sass';
@import '~bulma/sass/components/breadcrumb.sass';

.headroom--scrolled .navbar-wrapper {
  box-shadow: $tooltip-shadow;
}

.navbar-start,
.navbar-end {
  align-items: center;
}

.navbar-primary {
  background-position-y: 6px;
  .navbar-start,
  .navbar-end {
    background-color: $white;
  }
}

.navbar-primary .navbar-brand {
  a.navbar-item {
    display: block;
    padding-left: 0;
    padding: 0.45rem;
    margin-left: 0.35rem;
    background-color: $white;
    @include mobile {
      margin-left: -0.5rem;
      padding-left: 0;
    }
    @include tablet {
      padding: 0.5rem;
      padding-top: 0.3rem;
      padding-bottom: 0.6rem;
      margin-left: -0.75rem;
    }
    @include desktop {
      margin-left: -0.5rem;
      padding-left: 0;
    }
    &:hover {
      background-color: $white;
    }
  }
  img {
    height: 100%;
    max-height: none;
    width: 125px;
  }
  @include tablet {
    justify-content: space-between;
  }
  @include mobile {
    justify-content: space-between;
  }
}

.navbar-primary .navbar-item,
.navbar-primary .navbar-link {
  line-height: 1;
  padding-top: 0;
  padding-bottom: 0;
}

.breadcrumb {
  cursor: default;
  padding: 0 0.5rem 0 $navbar-margin;
  background-color: $white;
  font-size: $size-7;

  a {
    text-decoration: none;
    &:hover {
      text-decoration: underline;
      color: $primary;
    }
  }

  ul {
    align-items: center;
    color: $grey-dark;

    li + li::before {
      content: '';
    }
    li svg {
      fill: $primary;
      margin: 0 0.2rem 0 0.2rem;
    }

    .react-select {
      margin-left: 0.5rem;
    }

    li:hover {
      span {
        cursor: pointer;
        color: $link-color;
        text-decoration: underline;
      }
    }
    li:last-of-type {
      font-weight: 700;
      span {
        cursor: auto;
        color: $link-color;
        text-decoration: none;
      }
      color: $primary;
      .react-select__single-value {
        font-weight: 700;
        padding-bottom: 2px;
      }
      svg.is-separator {
        display: none;
      }
    }
  }
}

.navbar-item-mobile {
  background-color: $white;
  display: inline-flex;
  align-items: center;
}
