@import "~bulma/sass/utilities/initial-variables.sass"

$body-background-color: whitesmoke
$primary: hsla(0, 0%, 0%, 1)
$primary-disabled: hsla(0, 0%, 87%, 1)
$grey-light: hsl(0, 0%, 71%)
$grey: hsl(0, 0%, 48%)
$grey-dark: #595959
$size-7: .875rem
$size-8: .825rem
$family-primary: 'Roboto', 'Helvetica', 'Arial', sans-serif

$title-weight: $weight-bold
$link: $primary
$link-color: $primary

$button-bg: $primary
$button-hover: $grey
$control-radius: 0
$control-border-width: 2px

$navbar-height: 2.85rem
$navbar-margin: 2rem
$navbar-breakpoint: $desktop

$button-padding-vertical: 0.375em
$icon-button-padding: 0.2em

$footer-padding: 2rem
$footer-background-color: $primary
$footer-color: $white

$modal-card-head-background-color: $white
$modal-card-head-border-bottom: none
$modal-card-foot-border-top: none
$modal-card-head-radius: 0
$modal-card-foot-radius: 0
$modal-card-head-padding: 1rem 2rem 2rem 2rem
$modal-card-body-padding: 0 2rem

$navbar-item-hover-color: $primary
$navbar-item-hover-background-color: transparent
$section-padding-small: 2rem 1.5rem

$card-header-shadow: none
$card-footer-border-top: none
$card-shadow: 0px 4px 24px rgba(0, 0, 0, 0.05)
$card-shadow-hover: 0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23)
$tooltip-shadow: 0px 4px 24px rgba(0, 0, 0, 0.1)

$thumbnail-margin: 1rem

$tabs-link-hover-border-bottom-color: $grey-light

$transition: all 0.2s ease-in-out
$menu-item-color: $white-ter
$menu-item-hover-color: $white
$menu-item-hover-background-color: lighten($primary, 5%)
$menu-item-active-background-color: lighten($primary, 10%)
$menu-label-color: $grey-lighter

$modal-card-foot-border-top: none
$modal-card-head-background-color: $white
$modal-card-head-radius: $radius-small
$modal-card-foot-radius: $radius-small

@import "~bulma/sass/utilities/_all.sass"

