@charset 'utf-8';

:root {
  --toastify-toast-height: 56px;
}

.Toastify__toast {
  border-radius: 0;

  &.Toastify__toast-theme--colored {
    &.Toastify__toast--error {
      background-color: #fbe8e8;
      border: 1px solid #dc1919;
      & .Toastify__toast-icon {
        color: #dc1919;
      }
    }
    &.Toastify__toast--success {
      background-color: #edf9ed;
      border: 1px solid #4dc14d;
      & .Toastify__toast-icon {
        color: #4dc14d;
      }
    }
    &.Toastify__toast--info {
      background-color: #e7edfd;
      border: 1px solid #0f4fef;
      & .Toastify__toast-icon {
        color: #0f4fef;
      }
    }
  }
  & .Toastify__toast-body {
    color: #000;
  }
  & .Toastify__close-button {
    margin: 8px;
    align-self: center;
    color: #929292;
    & svg {
      width: 20px;
      height: 20px;
    }
  }
}
