@charset 'utf-8';
@import '../../../_variables.sass';

.navbar.navbar-secondary.is-dark {
  min-height: 2rem;
  font-size: $size-7;
  align-items: stretch;
  display: flex;
  .navbar-menu {
    flex-grow: 1;
    flex-shrink: 0;
    align-items: stretch;
    display: flex;
    z-index: 1;
  }
  .navbar-item {
    padding: 0;
    @include mobile {
      font-size: $size-7;
      padding: 0;
    }
  }
  .navbar-end {
    .button.is-primary {
      padding: 0.5em 0.8em;
      color: $grey-lighter;
      &.is-active {
        color: $white;
      }
      &:hover {
        background-color: transparent;
        color: $white;
      }
      &:last-of-type {
        border-left: 1px solid $white;
      }
      &.login {
        border-left: 0;
        color: $white;
      }
    }
    @include tablet {
      .navbar-item {
        display: none;
      }
    }
    @include desktop {
      .navbar-item {
        display: initial;
      }
    }
  }

  .navbar-start,
  .navbar-end {
    a.navbar-item:hover {
      background: lighten($primary, 10%);
    }
    a.navbar-item.is-active {
      background: lighten($primary, 20%);
      color: $grey-lighter;
    }
  }

  .react-select__control {
    background-color: $primary;
    border-color: $primary;
  }
  .react-select__single-value {
    color: $white;
  }
  .react-select__placeholder {
    color: $white;
  }

  .react-select__dropdown-indicator svg {
    fill: $white;
  }

  @include tablet {
    .navbar-menu {
      background: $primary;
      padding: 0;
    }

    .navbar-item {
      font-weight: $weight-bold;
      color: $white;
    }

    .navbar-start,
    .navbar-end {
      display: flex;
      flex-direction: row;
      height: 100%;
      flex: 1;
    }
  }
  @include mobile {
    .navbar-menu {
      background: $primary;
      padding: 0 0;
    }
    .navbar-start,
    .navbar-end {
      display: flex;
      flex-direction: row;
      height: 100%;
      flex: 1;
    }
    .navbar-item {
      font-weight: $weight-bold;
      color: $white;
      padding: 0;
    }
  }
}
