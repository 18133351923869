@charset 'utf-8';
@import '../../_variables.sass';

.home {
  position: relative;

  .section {
    padding: 2rem 1.5rem;
  }
}

.card-header-title {
  word-break: break-word;
}

.card.has-image {
  display: flex;
  flex-direction: column;
  min-height: 250px;
  height: 100%;
  &.is-disabled {
    opacity: 0.6;
    box-shadow: none;
    img {
      filter: grayscale(0.4);
    }
  }
  .card-image::after {
    content: '';
    display: block;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background-image: repeating-radial-gradient(
      circle,
      rgba(255, 255, 255, 0.2) 0px,
      rgba(255, 255, 255, 0.5) 1px,
      transparent 1px,
      transparent 100%
    );
    background-size: 16px 16px;
  }
  .card-content {
    padding: 0.75rem 0.75rem 1.25rem 0.75rem;
  }
}

.hero.dotted {
  background-position-y: -0.5rem;
}

.hero-body {
  @include mobile {
    margin: -1.5rem 1.5rem 0.5rem;
  }
  @include tablet {
    margin: -1.5rem 2rem 0.5rem;
  }
}

.role-tabs {
  .role-description {
    padding-top: 0;
  }
  .tabs {
    ul {
      display: block;
    }

    li {
      a {
        border-bottom-width: 2px;
      }

      &.is-active {
        position: relative;
        background-color: $grey-lighter;

        a {
          border-top: none;
          border-bottom-color: $primary;
          border-bottom-width: 2px;
          border-bottom-style: solid;
          transition: all $speed;
        }

        &::before {
          content: none;
          border: none;
        }
      }
    }
  }
}
